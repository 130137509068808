/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.site-layout-content {
    min-height: 280px;
    padding: 24px;
    background: #fff;
}

.logo {
    float: left;
    width: 160px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgb(255, 255, 255);
}

.ant-row-rtl .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

[data-theme="dark"] .site-layout-content {
    background: #141414;
}